import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H1, H4, Paragraph } from "../../styles/TextStyles"

interface OverseaSectionProps {
  title?: string
  description?: string
}

const OverseaSection: FunctionComponent<OverseaSectionProps> = props => {
  const {
    title = "Support Overseas",
    description = "We support you while you are out of your country to fulfill your stay while you are in the Republic of South Africa.",
  } = props
  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextWrapper>
        <CardHero>
          <Card>
            <Image src="/assets/cards/taxi.jpeg" />
            <Heading>Transportation Services</Heading>
            <Bullet>Airport pick up</Bullet>
            <Bullet>Airport drop off</Bullet>
            <Bullet>Private Taxi</Bullet>
          </Card>
          <Card>
            <Image src="/assets/cards/student.jpeg" />
            <Heading>Student Management Services</Heading>
            <Bullet>English School Application </Bullet>
            <Bullet>University Application</Bullet>
            <Bullet>SAQA Application</Bullet>
            <Bullet>Professional Bodies</Bullet>
          </Card>
          <Card>
            <Image src="/assets/cards/medical.jpeg" />
            <Heading>Medical Assistance services</Heading>
            <Bullet>Doctors Appointment</Bullet>
            <Bullet>Interpretation and translation</Bullet>
          </Card>
        </CardHero>
      </ContentWrapper>
    </Wrapper>
  )
}

export default OverseaSection

const Wrapper = styled.div`
  padding: 70px 100px 220px 100px;
`
const ContentWrapper = styled.div`
  display: grid;
  justify-content: center;
  gap: 40px;
`
const TextWrapper = styled.div`
  max-width: 600px;
  display: grid;
  justify-content: center;
  gap: 30px;
`
const Title = styled(H1)`
  font-weight: bold;
  @media (max-width: 640px) {
    font-size: 44px;
  }
`
const Description = styled(Paragraph)`
  line-height: 138%;
`
const CardHero = styled.div`
  display: grid;
  grid-template-columns: 300px 300px 300px;
  gap: 40px;

  @media (max-width: 1024px) {
    grid-template-columns: 200px 200px 200px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 200px 200px 200px;
  }
  @media (max-width: 640px) {
    grid-template-columns: 300px;
  }
`
const Card = styled.div`
  margin: 0;
`
const Image = styled.img`
  width: 300px;
  @media (max-width: 1024px) {
    width: 200px;
  }
  @media (max-width: 768px) {
    width: 200px;
  }
`
const Heading = styled(H4)`
  padding: 40px 0 20px 0;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`
const Bullet = styled.li`
  line-height: 188%;
  @media (max-width: 1024px) {
    font-size: 12px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`
